import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Emoji = makeShortcode("Emoji");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plugins are an extensible way to add functionality to tippy instances. By
splitting functionality into a plugin, components or routes that don't need the
plugin are not burdened with its bundle size cost.`}</p>
    <h3>{`Exported plugins`}</h3>
    <p>{`These plugins are exported by the package:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`animateFill`}</code>{` `}<Emoji emoji="🖌️" mdxType="Emoji" /></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`followCursor`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`inlinePositioning`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`sticky`}</code></li>
    </ul>
    <Emoji emoji="🖌️" mdxType="Emoji" /> Requires importing the following CSS stylesheets to work:
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js/dist/backdrop.css`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js/animations/shift-away.css`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Usage`}</h3>
    <h4>{`CDN (iife)`}</h4>
    <p>{`Included plugins (part of the `}<a parentName="p" {...{
        "href": "/all-props/"
      }}>{`All Props`}</a>{` table) will work as
normal.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`targets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  followCursor`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`Node (esm or cjs)`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` tippy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`followCursor`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`targets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  followCursor`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  plugins`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`[`}</span>{`followCursor`}<span parentName="code" {...{
            "className": "token block"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`Creating your own custom plugin`}</h3>
    <p>{`A plugin is created by defining an object with the following shape:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`plugin`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Optional (if the plugin provides a prop to use)`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`propName`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// e.g. 'followCursor' or 'sticky'`}</span>{`
  defaultValue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`anyValue`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Required`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fn`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Internal state`}</span>{`
    `}<span parentName="code" {...{
            "className": "token control keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Lifecycle hooks`}</span>{`
    `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The plugin's function `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fn`}</code>{` returns an object of
`}<a parentName="p" {...{
        "href": "/lifecycle-hooks/"
      }}>{`lifecycle hooks`}</a>{`.`}</p>
    <p>{`Here's an example of a plugin that causes a popper to hide if no elements within
it are in focus (for interactivity):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`hideOnPopperBlur`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`hideOnPopperBlur`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  defaultValue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fn`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token control keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`onCreate`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`popper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`focusout`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`event`}</span>{` `}<span parentName="code" {...{
            "className": "token arrow operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token control keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`
            `}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`hideOnPopperBlur`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
            `}<span parentName="code" {...{
            "className": "token object"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`relatedTarget`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`popper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`contains`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token object"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`relatedTarget`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`hide`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Our new prop is enabled by default (defaultValue: true)`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`targets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  plugins`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`[`}</span>{`hideOnPopperBlur`}<span parentName="code" {...{
            "className": "token block"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Plugins are invoked per-instance and the plugin function definition takes the
instance as an argument, so you can use private variables to create internal
state in the plugin closure. This is how the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`followCursor`}</code>{` plugin works.`}</p>
    <h3>{`TypeScript`}</h3>
    <p>{`Types that take `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Props`}</code>{` (e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Tippy`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Delegate`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CreateSingleton`}</code>{`) are
generics that accept an extended props interface:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}><pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` tippy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token class class-name"
          }}>{`Tippy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`Props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`Plugin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`LifecycleHooks`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`CustomProps`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  myCustomProp`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`boolean`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`FilteredProps`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`CustomProps`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`
  `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`Omit`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token class class-name"
          }}>{`Props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`keyof`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`CustomProps`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`keyof`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`LifecycleHooks`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`ExtendedProps`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`FilteredProps`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`LifecycleHooks`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token class class-name"
          }}>{`FilteredProps`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`myCustomProp`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`Plugin`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token class class-name"
          }}>{`ExtendedProps`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`myCustomProp`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  defaultValue`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`fn`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token arrow operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`default`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span>{`tippy `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`unknown`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`as`}</span>{` `}<span parentName="code" {...{
            "className": "token class class-name"
          }}>{`Tippy`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token class class-name"
          }}>{`ExtendedProps`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      